@font-face {
    font-family: "Apple SD Gothic Neo";
    src: url("./apple_sd_gothic_neo_r.ttf") format("truetype");
  }
  
body,
html,
#root
 {
    margin: auto;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    font-size: 12px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 100%;
    max-width: 480px;
    height: 100dvh;
    overflow: hidden;
    background-color: #131313;
}
:root{
    --black1: #131313;
    --black2: #4A4A4A;
    --black3: #4A4A4A;
    --black4: #747474;
    --black5: #393939;
    --blue1: #00AAFF;
    --gary8: #B5B5B5;
    --gray1: #EAEAEA;
    --gray2: #B7B7B7;
    --gray3: #868688;
    --gray4: #7B7B7B;
    --gray5: #959595;
    --gray6: #F6F6F6;
    --gray7: #9F9F9F;
    --green1: #03C75A;
    --green2: #06D964;
    --orange1: #FF5500;
    --red1: #FF0000;
    --white1: #FFFFFF;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
