.App {
  text-align: center;
  background-color: white;
  min-height: 100dvh;
  display: flex;    
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
  overflow: hidden;
}
div {
    display: flex;
    box-sizing:border-box;
    flex-direction: column;
    position: relative;
}
.App-link {
  color: #61dafb;
}

/* Spinner.css */
.overlay {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    z-index: 9999; /* Ensure it's on top of other elements */
}

.spinner {
    border: 4px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top: 4px solid var(--green1);
    width: 40px;
    height: 40px;
    -webkit-animation: spin 1s linear infinite;
    animation: spin 1s linear infinite;
}


@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}
  
/* HTML: <div class="loader"></div> */
.loader {
    width: 50px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: 
      radial-gradient(farthest-side,var(--green1) 94%,#0000) top/8px 8px no-repeat,
      conic-gradient(#0000 30%, var(--green1));
    -webkit-mask: radial-gradient(farthest-side,#0000 calc(100% - 8px),#000 0);
    animation: l13 1s infinite linear;
  }
  @keyframes l13{ 
    100%{transform: rotate(1turn)}
  }

  @keyframes ldio-pe8mtn3dpyh {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  .ldio-pe8mtn3dpyh div {
    left: 47.5px;
    top: 24px;
    position: absolute;
    animation: ldio-pe8mtn3dpyh linear 1s infinite;
    background: #b7b7b7;
    width: 5px;
    height: 12px;
    border-radius: 2.5px / 6px;
    transform-origin: 2.5px 26px;
  }.ldio-pe8mtn3dpyh div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #b7b7b7;
  }.ldio-pe8mtn3dpyh div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #b7b7b7;
  }.ldio-pe8mtn3dpyh div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #b7b7b7;
  }.ldio-pe8mtn3dpyh div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #b7b7b7;
  }.ldio-pe8mtn3dpyh div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #b7b7b7;
  }.ldio-pe8mtn3dpyh div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #b7b7b7;
  }.ldio-pe8mtn3dpyh div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #b7b7b7;
  }.ldio-pe8mtn3dpyh div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #b7b7b7;
  }.ldio-pe8mtn3dpyh div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #b7b7b7;
  }.ldio-pe8mtn3dpyh div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #b7b7b7;
  }.ldio-pe8mtn3dpyh div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #b7b7b7;
  }.ldio-pe8mtn3dpyh div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #b7b7b7;
  }
  .loadingio-spinner-spinner-c0ad5ihg5qq {
    width: 24px;
    height: 24px;
    display: inline-block;
    overflow: hidden;
    background: #ffffff;
  }
  .ldio-pe8mtn3dpyh {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(0.24);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-pe8mtn3dpyh div { box-sizing: content-box; }